import React from 'react'
import ResignFlow from './ResignFlow'
import _ from 'lodash'

export const makeFlow = function* (employee, procedureStatus) {
  if (_.isEmpty(employee) || _.isEmpty(employee.office)) {
    return
  }
  const healthInsuranceJoined = _.get(employee, 'health_insurance.joined')
  const welfarePensionInsuranceJoined = _.get(employee, 'welfare_pension_insurance.joined')
  const employmentInsuranceJoined = _.get(employee, 'employment_insurance.joined')
  const healthInsuranceType = _.get(employee, 'office.health_insurance_type')
  if (healthInsuranceJoined || welfarePensionInsuranceJoined) {
    if (healthInsuranceType === 'its') {
      yield 'R8'
    } else {
      yield 'R7'
    }
  }
  if (healthInsuranceJoined && healthInsuranceType === 'its') {
    const unableToReturnCount = procedureStatus?.r8_param_set?.unable_to_return_count || 0
    const lostCount = procedureStatus?.r8_param_set?.lost_count || 0
    if (unableToReturnCount > 0 || lostCount > 0) {
      yield 'R48'
    }
  }
  if (employmentInsuranceJoined) {
    yield 'R58'
  }
}

const Flow = ({location, match}) => <ResignFlow {...{makeFlow, location, match}} />

export default Flow
