import React, {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {reduxForm, FormSection, formValueSelector, autofill} from 'redux-form'
import {Section, TextAreaField, CheckboxField, DateField, RadioField, Label} from 'jbc-front/components/Form'
import Button from 'jbc-front/components/Button'
import FormErrors, {onSubmitFail, LabelMapper} from 'jbc-front/components/FormErrors'
import {toFormValues} from 'utils'
import connectForm from 'reportForms/connectForm'
import {getRelatedDependents} from 'procedures/changeDependents/utils'
import EmployeeName from 'procedures/EmployeeName'
import styles from './R48Report.scss'

const formName = 'R48Report'
const repeatNumber = 4
const repeatNumbers = [...Array(repeatNumber).keys()]
const reasonTypeOptions = [
  {value: 'none', label: '対象外'},
  {value: 'lost', label: '滅失'},
  {value: 'uncollected', label: '回収不能'},
]
const uncollectedReasonTypeOptions = [
  {value: 'reminded_more_than_three_times', label: '喪失日以降3回以上督促するも返納に応じないため'},
  {value: 'others', label: 'その他'},
]
const remindedTypeOptions = [
  {value: 'phone', label: '電話'},
  {value: 'email', label: 'メール'},
  {value: 'letter', label: '文書'},
  {value: 'oral', label: '口頭'},
  {value: 'others', label: 'その他'},
]

const maxLengthValidators = []
export const maxLength = (max, label) => {
  if (!maxLengthValidators[label]) {
    maxLengthValidators[label] = (value) =>
      value && value.length > max ? `${label}は${max}文字以内で入力してください` : undefined
  }
  return maxLengthValidators[label]
}

const HoverNote = ({message, showMessage, children}) =>
  showMessage ? (
    <div className={styles.hintWrap}>
      <div className={styles.hint}>{message}</div>
      {children}
    </div>
  ) : (
    children
  )

const CardTypeField = ({reasonTypeNone}) => (
  <>
    <Label text="滅失・回収不能となった証の種類" />
    <HoverNote
      message={
        <div>
          滅失・回収不能の区分が滅失又は回収不能の場合のみ
          <br />
          滅失・回収不能となった証の種類が入力可能です。
        </div>
      }
      showMessage={reasonTypeNone}
    >
      <div className={styles.cardType}>
        <CheckboxField name="is_certification_card" label="資格確認書" disabled={reasonTypeNone} />
        <CheckboxField name="is_health_insurance_card" label="被保険者証" disabled={reasonTypeNone} />
        <CheckboxField name="is_senior_card" label="高齢受給者証" disabled={reasonTypeNone} />
      </div>
    </HoverNote>
  </>
)

const LostForm = ({isLost}) => (
  <Section title="滅失に関する情報">
    <div>
      <HoverNote message="滅失・回収不能の区分が滅失の場合のみ滅失日の入力が可能です。" showMessage={!isLost}>
        <DateField name="lost_on" label="滅失日" disabled={!isLost} />
      </HoverNote>

      <HoverNote message="滅失・回収不能の区分が滅失の場合のみ滅失の状況の入力が可能です。" showMessage={!isLost}>
        <Label text="滅失の状況" />
        <TextAreaField name="lost_reason" disabled={!isLost} validate={maxLength(255, '滅失の状況')} />
      </HoverNote>
    </div>
  </Section>
)

const UncollectedForm = ({
  uncollectedReasonType,
  remindedTypeValues,
  isUncollected,
  onChangeUncollectedReasonType,
  onChangeRemindedType,
}) => (
  <Section title="回収不能に関する情報">
    <div>
      <HoverNote
        message="滅失・回収不能の区分が回収不能の場合のみ回収不能の理由の入力が可能です。"
        showMessage={!isUncollected}
      >
        <RadioField
          name="uncollected_reason_type"
          label="回収不能の理由"
          options={uncollectedReasonTypeOptions}
          onChange={onChangeUncollectedReasonType}
          disabled={!isUncollected}
        />
        　　　　
      </HoverNote>

      <HoverNote
        message="回収不能に関する情報＞回収不能の理由がその他の場合のみ理由入力欄の入力が可能です。"
        showMessage={uncollectedReasonType !== 'others'}
      >
        <TextAreaField
          name="uncollected_reason_detail"
          disabled={uncollectedReasonType !== 'others'}
          validate={maxLength(255, '回収不能に関する情報＞理由入力欄')}
        />
      </HoverNote>

      {[...Array(repeatNumber).keys()].map((i) => {
        return (
          <Section title={`返納督促をした経緯（${i + 1}回目）`} key={i}>
            <HoverNote
              message="滅失・回収不能の区分が回収不能の場合のみ督促年月日の入力が可能です。"
              showMessage={!isUncollected}
            >
              <DateField name={`reminded_on_${i}`} label="督促年月日" disabled={!isUncollected} />
            </HoverNote>

            <HoverNote
              message="滅失・回収不能の区分が回収不能の場合のみ督促手段の入力が可能です。"
              showMessage={!isUncollected}
            >
              <RadioField
                name={`reminded_type_${i}`}
                label="督促手段"
                options={remindedTypeOptions}
                disabled={!isUncollected}
                onChange={onChangeRemindedType}
              />
            </HoverNote>

            <HoverNote
              message="回収不能に関する情報>督促手段がその他の場合のみ督促手段入力欄の入力が可能です。"
              showMessage={remindedTypeValues[i] !== 'others'}
            >
              <TextAreaField
                name={`reminded_detail_${i}`}
                disabled={remindedTypeValues[i] !== 'others'}
                validate={maxLength(255, `返納督促をした経緯（${i + 1}回目）＞督促手段`)}
              />
            </HoverNote>
          </Section>
        )
      })}
    </div>
  </Section>
)

const R48Form = ({
  handleSubmit,
  submitting,
  submitText,
  procedureStatus,
  employee,
  reasonType,
  dependents,
  dependentParamSets,
  uncollectedReasonType,
  remindedTypeValues,
}) => {
  const r48Dependents = getRelatedDependents(dependents, procedureStatus)
  const dependentReasonTypes = dependentParamSets.map((dependentParamSet) => dependentParamSet.reason_type)
  const selectedReasonTypes = [...new Set([reasonType, ...dependentReasonTypes])]
  const isLost = selectedReasonTypes.includes('lost')
  const isUncollected = selectedReasonTypes.includes('uncollected')
  const dispatch = useDispatch()
  const onChangeReasonType = (_e, value) => {
    if (value === 'uncollected' && !selectedReasonTypes.includes('uncollected')) {
      // 滅失を選択した場合、デフォルト値をセット
      dispatch(autofill(formName, 'uncollected_reason_type', 'reminded_more_than_three_times'))
      repeatNumbers.forEach((number) => dispatch(autofill(formName, `reminded_type_${number}`, 'phone')))
    }
  }
  const onChangeUncollectedReasonType = (_e, value) => {
    // 回収不能の理由をその他以外にしたら理由入力欄をnullにする
    if (value !== 'others') {
      dispatch(autofill(formName, 'uncollected_reason_detail', null))
    }
  }
  const onChangeRemindedType = (_e, value, _previousValue, name) => {
    const index = name.slice(-1)

    // 督促手段をその他以外にしたら督促手段入力欄をnullにする
    if (value !== 'others') {
      dispatch(autofill(formName, `reminded_detail_${index}`, null))
    }
  }

  useEffect(() => {
    // 「滅失・回収不能の区分」を対象外にしたときは「滅失・回収不能となった証の種類」をすべてfalseにする
    if (reasonType === 'none') {
      dispatch(autofill(formName, 'is_certification_card', false))
      dispatch(autofill(formName, 'is_health_insurance_card', false))
      dispatch(autofill(formName, 'is_senior_card', false))
    }
  }, [reasonType])

  useEffect(() => {
    // 被扶養者用の「滅失・回収不能の区分」を対象外にしたときは「滅失・回収不能となった証の種類」をすべてfalseにする
    dependentParamSets.forEach((dependentParamSet, index) => {
      if (dependentParamSet.reason_type === 'none') {
        dispatch(autofill(formName, `r48_dependent_param_set[${index}].is_certification_card`, false))
        dispatch(autofill(formName, `r48_dependent_param_set[${index}].is_health_insurance_card`, false))
        dispatch(autofill(formName, `r48_dependent_param_set[${index}].is_senior_card`, false))
      }
    })
  }, [dependentReasonTypes])

  useEffect(() => {
    // 「滅失・回収不能の区分」を「滅失」以外にしたときは滅失に関する情報の項目をすべてnullにする
    if (!isLost && (selectedReasonTypes.includes('none') || selectedReasonTypes.includes('uncollected'))) {
      dispatch(autofill(formName, 'lost_on', null))
      dispatch(autofill(formName, 'lost_reason', null))
    }
  }, [isLost])

  useEffect(() => {
    // 「滅失・回収不能の区分」を「回収不能」以外にしたときは回収不能に関する情報の項目をすべてnullにする
    if (!isUncollected && (selectedReasonTypes.includes('none') || selectedReasonTypes.includes('lost'))) {
      dispatch(autofill(formName, 'uncollected_reason_type', null))
      dispatch(autofill(formName, 'uncollected_reason_detail', null))
      repeatNumbers.forEach((number) => {
        dispatch(autofill(formName, `reminded_on_${number}`, null))
        dispatch(autofill(formName, `reminded_type_${number}`, null))
        dispatch(autofill(formName, `reminded_detail_${number}`, null))
      })
    }
  }, [isUncollected])

  return (
    <form onSubmit={handleSubmit}>
      <div className="l-title-wrap">
        <h1 className="m-title-main">健康保険　滅失・回収不能届</h1>
        <EmployeeName employee={employee} />
      </div>

      <div className="basic_information">
        <FormErrors />
        {procedureStatus.procedure_type === 'resign' && (
          <Section title={employee.display_full_name}>
            <RadioField
              name="reason_type"
              label="滅失・回収不能の区分"
              options={reasonTypeOptions}
              onChange={onChangeReasonType}
            />
            <CardTypeField reasonTypeNone={reasonType === 'none'} />
          </Section>
        )}

        {r48Dependents &&
          r48Dependents.map((dependent, index) => {
            return (
              <FormSection name={`r48_dependent_param_set[${index}]`} key={dependent.id}>
                <LabelMapper name="r48_dependent_param_set" label="扶養情報" />
                <Section title={`${dependent.last_name} ${dependent.first_name}`}>
                  <RadioField
                    name="reason_type"
                    label="滅失・回収不能の区分"
                    options={reasonTypeOptions}
                    onChange={onChangeReasonType}
                  />
                  <CardTypeField reasonTypeNone={dependentParamSets[index]?.reason_type === 'none'} />
                </Section>
              </FormSection>
            )
          })}

        <LostForm isLost={isLost} />
        <UncollectedForm
          uncollectedReasonType={uncollectedReasonType}
          remindedTypeValues={remindedTypeValues}
          isUncollected={isUncollected}
          onChangeUncollectedReasonType={onChangeUncollectedReasonType}
          onChangeRemindedType={onChangeRemindedType}
        />

        <div className="u-ta-c u-mt30">
          <Button primary onClick={handleSubmit} disabled={submitting}>
            {submitText}
          </Button>
        </div>
      </div>
    </form>
  )
}

const Form = reduxForm({
  form: formName,
  enableReinitialize: true,
  onSubmitFail,
})(R48Form)

const makeInitialValues = ({dependents, data: reportValues, procedureStatus}) => {
  if (!dependents) {
    return toFormValues(reportValues)
  }

  const r48DependentParamSet = reportValues?.r48_dependent_param_set || []
  const r48Dependents = getRelatedDependents(dependents, procedureStatus)
  const r48DependentValues = r48Dependents.map((dependent) =>
    toFormValues(
      r48DependentParamSet.find((value) => value.employee_dependent_id === dependent.id) || {
        employee_dependent_id: dependent.id,
      }
    )
  )

  return {...toFormValues(reportValues), r48_dependent_param_set: r48DependentValues}
}
const selector = formValueSelector(formName)
const connectedForm = connectForm('r48', Form, {
  mapState: (state) => ({
    procedureStatus: state.procedureStatuses.current.data,
    employee: state.procedureStatuses.current.data?.employee,
    dependents: state.procedureStatuses.current.data?.employee?.employee_dependents,
    dependentParamSets: selector(state, 'r48_dependent_param_set') || [],
    reasonType: selector(state, 'reason_type'),
    uncollectedReasonType: selector(state, 'uncollected_reason_type'),
    remindedTypeValues: repeatNumbers.map((number) => selector(state, `reminded_type_${number}`)),
  }),
  makeInitialValues,
})

export default connectedForm
