import React from 'react'
import {useSelector, useDispatch} from 'react-redux'
import styles from 'components/PerPage.scss'

const options = [10, 20, 50, 100, 200]

export const PerPage = ({query, setQuery, ...rest}) => {
  const handleChange = (e) => setQuery({...query, page: 1, per_page: e.target.value})

  return (
    <select type="select" onChange={handleChange} className={styles.select} value={query.per_page} {...rest}>
      {options.map((perPage) => (
        <option key={perPage} value={perPage}>
          {perPage}件を表示
        </option>
      ))}
    </select>
  )
}

export const connectPerPage =
  (selector, actionCreator) =>
  ({...rest}) => {
    const query = useSelector((state) => selector(state))
    const dispatch = useDispatch()
    const setQuery = (newQuery) => dispatch(actionCreator(newQuery))

    return <PerPage query={query} setQuery={setQuery} {...rest} />
  }
