import React from 'react'

import {DateField, Section, RadioField, TextField, SelectField, CheckboxField} from 'jbc-front/components/Form'
import {Phone, Notes} from 'FormFields'

import {reduxForm} from 'redux-form'
import Button from 'jbc-front/components/Button'
import FormErrors, {onSubmitFail} from 'jbc-front/components/FormErrors'
import {toBooleanProps} from 'utils'
import EmployeeName from 'procedures/EmployeeName'
import ReviewComments from 'components/ReviewComments'

const formName = 'resignEmployeeForm'

const withholdingSlipReceiveTypes = [
  {value: 'email', label: 'メール'},
  {value: 'posting', label: '郵送'},
]

const inhabitantsTaxCollectTypes = [
  {value: 'from_next_company_allowance', label: '転職先にて特別徴収（給与天引き）を希望'},
  {value: 'pay_by_self', label: '普通徴収（残りの住民税を自分で支払う）を希望'},
  {value: 'from_last_allowance', label: '一括徴収（最後の給与で一括天引き）を希望'},
]

const requireResignationLetterOptions = [
  {value: 'true', label: '希望'},
  {value: 'false', label: '不要'},
]

const continueInsuranceAfterRetirementOptions = [
  {value: 'true', label: '任意継続を希望する'},
  {value: 'false', label: '任意継続を希望しない'},
]

const Form = ({submitting, handleSubmit, employee, comments, healthInsuranceType}) => (
  <form onSubmit={handleSubmit}>
    <div className="l-title-wrap">
      <h1 className="m-title-main">退職情報入力</h1>
      <EmployeeName employee={employee} />
    </div>
    <div className="basic_information">
      <FormErrors />
      <ReviewComments comments={comments} />
      <Section title="退職情報">
        <DateField name="last_worked_on" label="最終出勤日" required />
        <DateField name="retired_at" label="退職日" required />
        <TextField name="retire_reason" label="退職理由" />
        <TextField name="address" label="退職後の住所" />
        <TextField name="email" label="退職後の連絡用メールアドレス" />
        <Phone notes="離職票・給与明細などの送付に必要となります" />
        <RadioField
          name="withholding_slip_receive_type"
          label="源泉徴収票の渡し方"
          options={withholdingSlipReceiveTypes}
        />
        <SelectField
          name="inhabitants_tax_collect_type"
          label="住民税の徴収方法"
          options={inhabitantsTaxCollectTypes}
          notes="一括徴収（最後の給与で一括天引き）の場合、給与の額によっては普通徴収（残りの住民税を自分で支払う）になる場合があります。"
        />
        <RadioField
          name="require_resignation_letter"
          label="離職票の希望"
          {...toBooleanProps}
          options={requireResignationLetterOptions}
          notes="失業給付を受け取るために必要となります。"
        />
        <RadioField
          name="continue_insurance_after_retirement"
          label="退職後の保険加入の確認"
          {...toBooleanProps}
          options={continueInsuranceAfterRetirementOptions}
          notes="会社を辞めても「任意継続」により最大2年間健康保険に加入し続ける事ができます。ただし、その際の保険料は全額従業員負担であり、従業員本人が手続を行う必要があります。"
        />
        <DateField
          name="insurance_card_returned_on"
          label={healthInsuranceType === 'other' ? '健康保険証の返却日' : '資格確認書等の返却日'}
        />
        <CheckboxField
          name="insurance_card_lost"
          label={healthInsuranceType === 'other' ? '健康保険証を紛失しました' : '資格確認書等を紛失しました'}
        />
        <TextField name="next_company_name" label="次の勤務先名称（任意）" />
        <DateField name="next_company_joined_on" label="次の勤務先入社日（任意）" />
        <Notes />
      </Section>
      <div className="u-ta-c u-mt30">
        <Button primary onClick={handleSubmit} disabled={submitting}>
          完了
        </Button>
      </div>
    </div>
  </form>
)

export const formatValues = ({date_on: dateOn, ...employee}) => ({
  dateOn,
  employee: {
    retired_at: dateOn,
    ...employee,
  },
})

export default reduxForm({
  form: formName,
  enableReinitialize: true,
  onSubmitFail,
})(Form)
