import type {FC, ChangeEvent} from 'react'
import styles from './PerPage.scss'

interface PerPageProps {
  options?: number[]
  selected?: number
  onChange: (perPage: number) => void
}

export const PerPage: FC<PerPageProps> = ({options = [10, 20, 50, 100, 200], selected = 100, onChange}) => {
  const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    onChange(Number(e.target.value))
  }

  return (
    <select onChange={handleChange} className={styles.select} defaultValue={selected}>
      {options.map((perPage: number) => (
        <option key={`perPage-key-${perPage}`} value={perPage}>
          {perPage}件を表示
        </option>
      ))}
    </select>
  )
}
