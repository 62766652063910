import React, {Component} from 'react'
import Form from 'procedures/resign/EmployeeForm'
import {connect} from 'react-redux'
import api from 'api'
import {actionCreators} from 'actions'
import {push} from 'connected-react-router'
import {toFormValues} from 'utils'
import {notifySuccess} from 'store/actions/notify'
import {asyncError} from 'store/actions/asyncError'

class Confirm extends Component {
  componentDidMount() {
    const {
      loadProcedureStatus,
      token,
      match: {
        params: {id},
      },
    } = this.props
    loadProcedureStatus(id, token)
  }

  componentWillUnmount() {
    this.props.destroy()
  }

  render() {
    const {
      procedureStatus: {resign_employee_input_datum: inputData, employee: employee},
      healthInsuranceType,
    } = this.props
    return (
      <Form
        onSubmit={this.handleSubmit}
        initialValues={inputData ? toFormValues(inputData) : {}}
        employee={employee}
        healthInsuranceType={healthInsuranceType}
      />
    )
  }

  handleSubmit = (values) => {
    const {updateAndCreateProcedure, token, procedureStatus} = this.props
    return updateAndCreateProcedure(procedureStatus, values, token)
  }
}

export default connect(
  (state) => ({
    token: state.auth.token,
    procedureStatus: state.procedureStatuses.current.data,
    healthInsuranceType: state.procedureStatuses.current.data.employee?.office?.health_insurance_type,
  }),
  (dispatch) => ({
    loadProcedureStatus: (id, token) => {
      dispatch(
        actionCreators.procedureStatuses.current.fetchData(
          api.createWithAuth(token).procedureStatuses.get(id, ['resign_employee_input_datum', 'employee', 'office'])
        )
      )
    },
    destroy: () => {
      dispatch(actionCreators.procedureStatuses.current.destroy())
    },
    updateAndCreateProcedure: async (procedureStatus, values, token) => {
      try {
        await api.createWithAuth(token).procedureStatuses.resignEmployeeInputData.update(procedureStatus.id, values)
        dispatch(notifySuccess('保存しました'))
        dispatch(push(`/procedure_statuses/${procedureStatus.id}`))
      } catch (err) {
        dispatch(asyncError(err))
      }
    },
  })
)(Confirm)
