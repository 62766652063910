import React, {Component} from 'react'
import {connect} from 'react-redux'
import {push} from 'connected-react-router'
import {actionCreators} from 'actions'
import api from 'api'
import _ from 'lodash'
import {formMap} from 'procedureStatuses/ReportEdit'
import {Redirect, withRouter} from 'react-router'
import {asyncError} from 'store/actions/asyncError'
import Loading from 'components/Loading'
import {formValueSelector} from 'redux-form'

class ResignFlow extends Component {
  static defaultProps = {
    flowStatus: {},
  }

  componentDidMount() {
    const {
      match: {
        params: {id},
      },
      token,
      loadProcedure,
      loadTmpData,
      params,
    } = this.props
    loadProcedure(id, token, params)
    loadTmpData(id, token)
  }

  componentWillUnmount() {
    this.props.destroy()
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.loading && this.props.loading) {
      const {
        procedureStatus,
        setStatus,
        match: {
          params: {id},
        },
        flowStatus,
      } = nextProps
      if (procedureStatus.status !== 'draft') {
        nextProps.history.replace(`/procedure_statuses/${id}`)
        return
      }
      if (!flowStatus.detailFinished) {
        nextProps.history.replace(`/${procedureStatus.procedure_type}/detail/${id}`)
        return
      } else {
        const status = flowStatus.status || {}
        const {
          employee,
          makeFlow,
          startProgress,
          token,
          match: {params},
        } = nextProps
        const flow = [...makeFlow(employee, procedureStatus)]
        if (flow.every((report) => status[report])) {
          startProgress(token, params.id)
        } else {
          setStatus(status)
        }
      }
    }
  }

  afterSubmit = async () => {
    const {
      current,
      finishStep,
      status,
      token,
      startProgress,
      match: {params},
      saveStatus,
      makeFlow,
      employee,
      procedureStatus,
      loadProcedure,
      unableToReturnCount,
      lostCount,
    } = this.props
    const flow = [...makeFlow(employee, procedureStatus)]
    finishStep(current)
    // 表示している画面がITSけんぽ「健康保険厚生年金保険被保険者資格喪失届(R8)」の場合
    if (current === 'R8') {
      // 返不能枚数、または滅失枚数が1枚以上の場合、「健康保険 滅失・回収不能届」へ
      if (unableToReturnCount > 0 || lostCount > 0) {
        flow.splice(1, 0, 'R48')
      }
    }
    const currentIndex = _.indexOf(flow, current)
    const nextIndex = flow.findIndex((value, index) => index > currentIndex && !status[value])
    if (nextIndex < 0) {
      startProgress(token, params.id)
    } else {
      // r8_param_setを取得するため
      if (current == 'R8') {
        await loadProcedure(params.id, token, params)
      }
      await saveStatus(token, params.id, status, current)
      this.props.history.push({pathname: this.props.location.pathname, hash: `#${flow[nextIndex]}`})
      window.scrollTo(0, 0)
    }
  }

  render() {
    const {
      current,
      match: {
        params: {id},
      },
      makeFlow,
      employee,
      status,
      procedureStatus,
      loading,
    } = this.props
    if (!status) {
      return null
    }
    if (loading) {
      return (
        <div className="l-wrap-xs u-pt100">
          <div className="l-box-message">
            <Loading />
          </div>
        </div>
      )
    }

    const flow = [...makeFlow(employee, procedureStatus)]
    if (!flow.includes(current)) {
      const notFinished = _.find(flow, (value) => !status[value])
      return notFinished ? <Redirect to={{pathname: this.props.location.pathname, hash: `#${notFinished}`}} /> : null
    }
    const [report, dependentId] = current ? current.split('_') : []
    const Form = report ? formMap[report] : null
    return (
      <div>
        {current && Form && (
          <Form
            key={dependentId}
            submitText="次へ"
            procedureStatusId={id}
            afterSubmit={this.afterSubmit}
            dependentId={dependentId}
          />
        )}
      </div>
    )
  }
}

export default withRouter(
  connect(
    (state, ownProps) => {
      const current = ownProps.location.hash ? ownProps.location.hash.slice(1) : ''
      const employee = _.get(state, 'procedureStatuses.current.data.employee')
      const formName = 'R8Report'
      const selector = formValueSelector(formName)

      return {
        token: state.auth.token,
        procedureStatus: state.procedureStatuses.current.data,
        flowStatus: state.procedureStatuses.tmpData.data.draft_flow_status,
        loading:
          state.procedureStatuses.tmpData.loading ||
          state.procedureStatuses.current.loading ||
          _.isEmpty(employee) ||
          _.isEmpty(employee.office),
        employee,
        status: state.procedures.status,
        current,
        unableToReturnCount: selector(state, 'unable_to_return_count'), // 返不能枚数
        lostCount: selector(state, 'lost_count'), // 滅失枚数
      }
    },
    (dispatch) => ({
      finishStep: (current) => dispatch(actionCreators.procedures.finishStep(current)),
      loadProcedure: (id, token, params) => {
        dispatch(
          actionCreators.procedureStatuses.current.fetchData(
            api
              .createWithAuth(token)
              .procedureStatuses.get(
                id,
                [
                  'employee',
                  'employee_dependents',
                  'health_insurance',
                  'welfare_pension_insurance',
                  'employment_insurance',
                  'my_number_present',
                  'office',
                  'exist_procedure_change_name',
                  'r8_param_set',
                ].filter(_.identity),
                params
              )
          )
        )
      },
      loadTmpData(id, token) {
        dispatch(
          actionCreators.procedureStatuses.tmpData.fetchData(
            api.createWithAuth(token).procedureStatuses.tmpData.mapToJson(id, 'draft', 'flow_status')
          )
        )
      },
      destroy() {
        dispatch(actionCreators.procedureStatuses.current.destroy())
        dispatch(actionCreators.procedureStatuses.tmpData.destroy())
        dispatch(actionCreators.procedures.setStatus(null))
      },
      setStatus: (status) => {
        dispatch(actionCreators.procedures.setStatus(status))
      },
      startProgress: async (token, procedureStatusId) => {
        try {
          await api.createWithAuth(token).procedureStatuses.startProgress(procedureStatusId)
          dispatch(push(`/procedure_statuses/${procedureStatusId}`))
        } catch (err) {
          dispatch(asyncError(err))
        }
      },
      saveStatus: async (token, procedureStatusId, status, current) => {
        try {
          await api.createWithAuth(token).procedureStatuses.tmpData.updateJson(
            procedureStatusId,
            'draft',
            'flow_status',
            (
              data = {
                detailFinished: true,
                status: {},
              }
            ) => ({
              ...data,
              status: {
                ...data.status,
                [current]: true,
              },
            })
          )
        } catch (err) {
          dispatch(asyncError(err))
        }
      },
    })
  )(ResignFlow)
)
