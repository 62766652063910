import React from 'react'
import Select from 'react-select'
import classNames from 'classnames'
import {SearchResult} from 'tm/employees/list/SearchForm'
import styles from 'tm/notifications/list/PerPageWithResult.scss'

const limits = ['10', '20', '50', '100', '200'].map((ele) => ({value: ele, label: `${ele}件を表示`}))

const PerPageWithResult = ({total, page, limit, onChangeLimit, isLoading}) => {
  return (
    <div className={classNames('u-mt10', styles.PerPageWithResult)}>
      <div className={styles.searchSelectBox}>
        <div className={styles.select}>
          <Select
            options={limits}
            searchable={false}
            clearable={false}
            simpleValue
            value={limit}
            onChange={(newValue) => onChangeLimit(newValue)}
          />
        </div>
      </div>
      <div className={styles.listTypeSearchResult}>
        <SearchResult total={total} page={page} limit={limit} isLoading={isLoading} />
      </div>
      <div className={classNames('u-mb10', styles.resultRight)} />
    </div>
  )
}

export default PerPageWithResult
