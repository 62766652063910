import React from 'react'
import RemoveDependentFlow from './RemoveDependentFlow'
import _ from 'lodash'
import {getRemovedDependents} from 'procedures/changeDependents/utils'

export const makeFlow = function* (employee, procedureStatus) {
  if (_.isEmpty(employee) || _.isEmpty(employee.office)) {
    return
  }
  const healthInsuranceJoined = _.get(employee, 'health_insurance.joined')
  const healthInsuranceType = _.get(employee, 'office.health_insurance_type')
  const welfarePensionInsuranceJoined = _.get(employee, 'welfare_pension_insurance.joined')
  const dependents = getRemovedDependents(employee.employee_dependents, procedureStatus)
  const spouse = dependents.find((dependent) => dependent.relation_type === 'spouse')

  if (healthInsuranceJoined) {
    if (healthInsuranceType === 'its') {
      yield 'R5'

      const unableToReturnCount = procedureStatus?.r5_param_set?.unable_to_return_count || 0
      const lostCount = procedureStatus?.r5_param_set?.lost_count || 0
      if (unableToReturnCount > 0 || lostCount > 0) {
        yield 'R48'
      }
    } else {
      yield 'R4'
    }
  }
  if (welfarePensionInsuranceJoined && spouse) {
    if (healthInsuranceType !== 'kyokai') {
      yield 'R6'
    }
  }
}

const Flow = ({location, match}) => <RemoveDependentFlow {...{makeFlow, location, match}} />

export default Flow
